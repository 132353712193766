import type { Product } from '../interfaces/Product';

export const products: Product[] = [
  {
    name: 'Açaí Berry',
    description: 'Rich in antioxidants and heart-healthy fats, our pure Açaí powder delivers the authentic taste of the Amazon.',
    image: 'https://images.pexels.com/photos/1153655/pexels-photo-1153655.jpeg?auto=compress&cs=tinysrgb&w=800',
    benefits: [
      'High in antioxidants',
      'Supports heart health',
      'Boosts energy levels',
      'Enhances immune system'
    ],
    category: 'ambient'
  },
  {
    name: 'Maca Root',
    description: 'Ancient Peruvian superfood known for boosting energy, endurance, and hormonal balance.',
    image: 'https://images.pexels.com/photos/4110250/pexels-photo-4110250.jpeg?auto=compress&cs=tinysrgb&w=800',
    benefits: [
      'Natural energy booster',
      'Hormonal balance support',
      'Improves endurance',
      'Rich in vitamins & minerals'
    ],
    category: 'ambient'
  },
  {
    name: 'Raw Cacao',
    description: 'Organic raw cacao packed with magnesium, iron, and mood-enhancing compounds.',
    image: 'https://images.pexels.com/photos/867470/pexels-photo-867470.jpeg?auto=compress&cs=tinysrgb&w=800',
    benefits: [
      'High in magnesium',
      'Rich in antioxidants',
      'Mood enhancer',
      'Natural energy boost'
    ],
    category: 'ambient'
  },
  {
    name: 'Guarana Seed',
    description: 'Natural energy booster with cognitive-enhancing properties from the Amazon rainforest.',
    image: 'https://images.pexels.com/photos/2067396/pexels-photo-2067396.jpeg?auto=compress&cs=tinysrgb&w=800',
    benefits: [
      'Natural caffeine source',
      'Improves focus',
      'Boosts metabolism',
      'Enhances mental clarity'
    ],
    category: 'frozen'
  },
  {
    name: 'Brazil Nuts',
    description: 'Selenium-rich nuts that support thyroid function and immune system health.',
    image: 'https://images.pexels.com/photos/4033296/pexels-photo-4033296.jpeg?auto=compress&cs=tinysrgb&w=800',
    benefits: [
      'High in selenium',
      'Supports thyroid health',
      'Heart-healthy fats',
      'Protein-rich snack'
    ],
    category: 'frozen'
  },
  {
    name: 'Sacha Inchi',
    description: 'Ancient Incan superfood rich in omega-3s, protein, and vitamin E.',
    image: 'https://images.pexels.com/photos/1907642/pexels-photo-1907642.jpeg?auto=compress&cs=tinysrgb&w=800',
    benefits: [
      'Rich in omega-3',
      'Complete protein source',
      'High in vitamin E',
      'Supports joint health'
    ],
    category: 'frozen'
  }
];
