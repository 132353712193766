export function initializeSwipeHandler() {
  const container = document.getElementById('carousel-container');
  if (!container) return;

  let startX = 0;
  let currentIndex = 0;
  let isTransitioning = false;
  let startTime = 0;
  let isSwiping = false;

  // Function to update product dots
  function updateProductDots(index: number) {
    document.querySelectorAll('.product-dot').forEach((dot, i) => {
      if (i === index) {
        dot.classList.remove('scale-60');
        dot.classList.add('scale-100');
      } else {
        dot.classList.remove('scale-100');
        dot.classList.add('scale-60');
      }
    });
  }

  function moveToIndex(targetIndex: number) {
    if (isTransitioning) return;
    
    const prevBtn = document.getElementById('prevBtn') as HTMLButtonElement;
    const nextBtn = document.getElementById('nextBtn') as HTMLButtonElement;
    const dots = document.querySelectorAll('.product-dot');
    
    // Validate target index
    if (targetIndex < 0 || targetIndex >= dots.length) return;
    
    // Update navigation button states
    if (prevBtn) prevBtn.disabled = targetIndex === 0;
    if (nextBtn) nextBtn.disabled = targetIndex === dots.length - 1;

    isTransitioning = true;
    
    // Get the dot at target index and simulate a click
    const targetDot = dots[targetIndex] as HTMLElement;
    if (targetDot) {
      targetDot.click();
      currentIndex = targetIndex;
      updateProductDots(currentIndex);
    }

    // Reset transition lock after animation completes
    setTimeout(() => {
      isTransitioning = false;
    }, 350);
  }

  container.addEventListener('touchstart', (e: TouchEvent) => {
    if (isTransitioning) return;
    startX = e.touches[0].clientX;
    startTime = Date.now();
    isSwiping = false;
  }, { passive: true });

  container.addEventListener('touchmove', (e: TouchEvent) => {
    if (isTransitioning) return;
    
    const deltaX = e.touches[0].clientX - startX;
    
    // Only prevent default if we determine this is an intentional horizontal swipe
    if (Math.abs(deltaX) > 20 && !isSwiping) {
      isSwiping = true;
      e.preventDefault();
    }
  }, { passive: false });

  container.addEventListener('touchend', (e: TouchEvent) => {
    if (isTransitioning) return;
    
    const endX = e.changedTouches[0].clientX;
    const deltaX = endX - startX;
    const deltaTime = Date.now() - startTime;
    
    // Calculate swipe velocity (pixels per millisecond)
    const velocity = Math.abs(deltaX) / deltaTime;
    
    // Require either a significant swipe distance or a fast swipe
    const swipeThreshold = window.innerWidth * 0.15; // 15% of screen width
    if ((Math.abs(deltaX) > swipeThreshold || (Math.abs(deltaX) > 30 && velocity > 0.5)) && isSwiping) {
      if (deltaX > 0) {
        moveToIndex(currentIndex - 1);
      } else {
        moveToIndex(currentIndex + 1);
      }
    }
    
    isSwiping = false;
  }, { passive: true });

  // Reset index when changing categories
  document.querySelectorAll('.category-toggle').forEach(button => {
    button.addEventListener('click', () => {
      currentIndex = 0;
      isTransitioning = false;
      isSwiping = false;
      updateProductDots(0);
    });
  });
}

document.addEventListener('DOMContentLoaded', initializeSwipeHandler);
document.addEventListener('astro:page-load', initializeSwipeHandler);
